var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "d-flex align-center" }, [
    _c(
      "div",
      {
        staticClass: "d-flex flex-column align-stretch",
        staticStyle: { width: "60%" },
      },
      [
        _c("pie-chart", {
          staticStyle: { height: "200px" },
          attrs: { data: _vm.chartData, options: _vm.chartOptions },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-center justify-space-between" },
          [
            _c("div", { staticClass: "route-bar-label text-right" }, [
              _vm._v(_vm._s(_vm.$t("indoor_short"))),
            ]),
            _vm._v(" "),
            _c("bar-chart", {
              staticClass: "route-bar",
              attrs: {
                data: _vm.categoryChartData,
                options: _vm.categoryChartOptions,
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "route-bar-label" }, [
              _vm._v(_vm._s(_vm.$t("outdoor_short"))),
            ]),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticStyle: { width: "40%" } }, [
      _c(
        "ol",
        { staticStyle: { "list-style-type": "none" } },
        _vm._l(_vm.chartInsectCounts, function (insectCount, index) {
          return _c("li", [
            _c("div", {
              staticClass: "insect-color",
              style: { backgroundColor: _vm.colors[index] },
            }),
            _vm._v("\n        " + _vm._s(insectCount.name) + "\n      "),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }