<template>
  <div class="sensor-status">
    <ul data-testid="HealthCheckStatusList">
      <li class="sensor-status__item sensor-status__item--success" v-if="_.isEmpty(results)">
        {{$t('allgreen')}}
      </li>
      <template v-else>
        <li
          :class="`sensor-status__item sensor-status__item--${result.status}`"
          v-for="result in results"
        >
          {{result.name}}: {{result.message}}
        </li>
      </template>
      <li
        class="sensor-status__item sensor-status__item--info"
        v-if="firmwareVersion"
      >
        {{$t('firmware_version')}}: {{firmwareVersion}}
      </li>
      <li
        class="sensor-status__item sensor-status__item--info"
        v-if="ipAddress"
      >
        {{$t('ip_address')}}: {{ipAddress}}
      </li>
    </ul>
    <a
      class="sensor-status__download"
      :href="download"
      download="health_check.json"
      v-if="canDownload && !_.isEmpty(results)"
    >
      <span>
        {{$t('download')}}
      </span>
      <v-icon>download</v-icon>
    </a>
  </div>
</template>

<i18n lang="yaml">
ja:
  allgreen: 異常なし
  firmware_version: ファームウェアのバージョン
  ip_address: ローカルIPアドレス
  download: 最新のログをダウンロードする

en:
  allgreen: Normal
  firmware_version: Firmware Version
  ip_address: Local IP Address
  download: Download latest log

</i18n>

<script>
import SensorHealthCheck from '@/mixins/sensorHealthCheck';

export default {
  name: 'SensorStatus',
  mixins: [SensorHealthCheck],
  props: {
    canDownload: Boolean,
    status: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    download() {
      const json = JSON.stringify(this.status, null, 2);
      return URL.createObjectURL(new Blob([json]), { type: 'text/json' });
    },
    results() {
      return this.healthCheck({ health_check_status: this.status });
    },
    firmwareVersion() {
      return this._.get(this.status, 'app_info.version', '');
    },
    ipAddress() {
      return this._.get(this.status, 'network.ip_address', '');
    },

  },
};
</script>

<style scoped lang="sass">
.sensor-status
  font-size: 80%

  ul
    padding-left: 0px

  &__item
    display: flex
    align-items: baseline
    white-space: nowrap
    margin: 0.5rem 0 0

    &::before
      display: block
      content: ''
      min-height: 10px
      min-width: 10px
      margin-right: 7px
      margin-left: 5px
      border-radius: 12px
      background-color: grey

    &--success::before
      background-color: limegreen

    &--error::before
      background-color: red

    &--info::before
      visibility: hidden

  &__download
    display: inline-block
    margin-top: 1rem
    margin-left: 22px

    span
      text-decoration: underline

    .v-icon
      color: inherit
      font-size: 14px
</style>
