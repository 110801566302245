<template>
  <div class="d-flex align-center">
    <div class="d-flex flex-column align-stretch" style="width: 60%">
      <pie-chart
        style="height: 200px"
        :data="chartData"
        :options="chartOptions"
      />
      <div class="d-flex align-center justify-space-between">
        <div class="route-bar-label text-right">{{ $t('indoor_short') }}</div>
        <bar-chart
          class="route-bar"
          :data="categoryChartData"
          :options="categoryChartOptions"
          />
        <div class="route-bar-label">{{ $t('outdoor_short') }}</div>
      </div>
    </div>
    <div style="width: 40%">
      <ol style="list-style-type: none">
        <li v-for="(insectCount, index) in chartInsectCounts">
          <div
            class="insect-color"
            :style="{ backgroundColor: colors[index] }"
          />
          {{ insectCount.name }}
        </li>
      </ol>
    </div>
  </div>
</template>

<i18n lang="yaml">
ja:
  indoor: 内部発生
  indoor_short: 内部
  outdoor: 外部発生
  outdoor_short: 外部
en:
  indoor: indoor
  indoor_short: indoor
  outdoor: outdoor
  outdoor_short: outdoor
</i18n>

<script>

import insectDescriptions from 'Data/insect_descriptions.json';

import theme from '@/libs/theme';
import InsectTypes from '@/mixins/insectTypes';

import BarChart from '@/components/atoms/BarChart';
import PieChart from '@/components/atoms/PieChart';

export const COLORS = [
  '#f44822',
  '#ffa629',
  '#ffcd2a',
  '#13ae5c',
  '#9747ff',
  '#b8b8b8', // Others
];

export default {
  name: 'insect-type-pie-chart',
  components: {
    BarChart,
    PieChart,
  },
  mixins: [InsectTypes],
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  computed: {
    colors() {
      return COLORS;
    },
    categoryChartData() {
      return {
        labels: [''],
        datasets: [
          {
            backgroundColor: theme.primaryColor,
            label: this.$t('indoor'),
            data: [this.categoryPercentages.indoor],
          },
          {
            backgroundColor: theme.warningColor,
            label: this.$t('outdoor'),
            data: [this.categoryPercentages.outdoor],
          },
        ],
      };
    },
    categoryChartOptions() {
      return {
        indexAxis: 'y',
        plugins: {
          datalabels: {
            display: true,
            color: 'white',
            font: {
              size: 14,
              weight: 'bold',
            },
            formatter: function (value) {
              return `${value.toFixed(0)}%`;
            },
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        scales: {
          x: {
            display: false,
            max: 100,
            stacked: true,
          },
          y: {
            stacked: true,
            display: false,
          },
        },
      };
    },
    categoryPercentages() {
      const counts = { indoor: 0, outdoor: 0 };

      this.totalInsectCounts.forEach(({ count, category }) => {
        counts[category] += count;
      });

      const total = counts.indoor + counts.outdoor;

      return {
        indoor: (counts.indoor / total) * 100,
        outdoor: (counts.outdoor / total) * 100,
      };
    },
    chartData() {
      return {
        labels: this._.map(this.chartInsectCounts, 'name'),
        datasets: [
          {
            backgroundColor: this.colors,
            data: this._.map(this.chartInsectCounts, 'count'),
          },
        ],
      };
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
            // BarChartとPieChartの動きを統一する為、クリックイベントを無効化
            onClick: function () {},
          },
        },
      };
    },
    chartInsectCounts() {
      const others = this._.find(this.totalInsectCounts, { type: 'others' });
      const insectCountsWithoutOthers = this._.without(this.totalInsectCounts, others);
      const sortedInsectCounts = this._.sortBy(insectCountsWithoutOthers, 'count');
      sortedInsectCounts.reverse();

      const top5 = sortedInsectCounts.slice(0, 5);
      const notTop5 = sortedInsectCounts.slice(5);
      const othersSummary = {
        name: others.name,
        count: others.count + this._.sumBy(notTop5, 'count'),
      };

      return [
        ...top5,
        othersSummary,
      ];
    },
    totalInsectCounts() {
      return Object.entries(this.INSECT_TYPES).map(([type, { label }]) => {
        const category = type === 'others' ? 'outdoor' : insectDescriptions[type].en.route;

        let lastCount = 0;
        let count = 0;

        this.events.forEach((event) => {
          const insectCount = event.insect_counts[type] || 0;
          count += Math.max(0, insectCount - lastCount);
          lastCount = insectCount;
        });

        return {
          type,
          category,
          name: label,
          count,
        };
      });
    },
  },
};
</script>

<style scoped lang="sass">
.insect-color
  display: inline-block
  width: 1rem
  height: 1rem
  margin-bottom: -0.2rem

.route-bar
  width: 60%
  height: 50px

.route-bar-label
  width: 20%
  font-weight: bold
  padding-left: 8px
  padding-right: 8px
</style>
