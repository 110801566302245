<template>
  <div>
    <page-title-area>
      <h1>{{$t('title')}}</h1>
    </page-title-area>
    <div class="filter-area d-flex align-center mb-4 py-1">
      <month-picker :value="currentMonth" disabled />
      <select-box
        class="flex-grow-0"
        style="width: 300px"
        v-model="selectedSensorId"
        :items="sensorSelectItems"
        :label="$t('field.sensor')"
        data-testid="sensor-select"
      />
      <insect-type-select-box
        v-model="insectTypeFilter"
        :null-option="$t('all_insects_option')"
        :label="$t('field.insect_type')"
      />
    </div>
    <v-row dense>
      <v-col cols="3">
        <v-card class="graph-area" style="height: 200px">
          <div>
            <h2>{{ $t('total_count') }}</h2>
          </div>
          <total-count-panel
            :eventData="selectedSensorData"
            :month="currentMonth"
          />
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card class="graph-area d-flex flex-column" style="height: 200px">
          <div>
            <h2>{{ $t('alert') }}</h2>
          </div>
          <hr class="mx-n4">
          <notice-history
            class="mx-n4 mb-n4"
            :events="currentMonthSelectedSensorEvents"
            :sensors="sensors"
          />
        </v-card>
      </v-col>
      <v-col cols="7">
        <v-row dense>
          <v-col cols="12">
            <v-card class="graph-area" style="height: 320px">
              <div class="d-flex">
                <h2>{{ $t('insect_count') }}</h2>
                <v-spacer />
                <alert-legend v-if="showAlertPeriods"/>
              </div>
              <daily-insect-count-chart
                style="height: 250px"
                :event-data="selectedSensorData"
                :graphs="comparedWithPreviousMonthGraphs"
                :y-max="dailyInsectCountYMax"
                :show-alert-periods="showAlertPeriods"
                @max-count-updated="value => updateMaxDailyInsectCount(0, value)"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="5">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('top5') }}</h2>
          </div>
          <insect-type-pie-chart
            :events="currentMonthSelectedSensorEvents"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('hourly_insect_count') }}</h2>
          </div>
          <hourly-bar-chart
            style="height: 250px"
            class="count-graph"
            :event-data="currentMonthSelectedSensorEvents"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('weekly_insect_count') }}</h2>
          </div>
          <weekday-bar-chart
            style="height: 250px"
            class="count-graph"
            :event-data="currentMonthSelectedSensorEvents"
          />
        </v-card>
      </v-col>
      <v-col cols="4">
        <v-card class="graph-area" style="height: 320px">
          <div>
            <h2>{{ $t('weekly_and_hourly_insect_count') }}</h2>
          </div>
          <weekday-hour-matrix-chart
            style="height: 250px"
            :event-data="currentMonthSelectedSensorData"
          />
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<i18n lang="yaml">
ja:
  current_month: 当月
  total_count: 総捕獲数
  insect_count: 捕獲数
  previous_month: 前月
  same_month_previous_year: 昨年同月
  hourly_insect_count: 時間帯別 捕獲数
  weekly_insect_count: 曜日別 捕獲数
  weekly_and_hourly_insect_count: 曜日 x 時間帯別 捕獲数
  sum: 合計 ({sensorCount}台)
  top5: 捕獲上位5種
  title: モニタリングサマリー
  all_insects_option: 全種合計
  alert: アラート
  field:
    sensor: センサー
    insect_type: 虫種

en:
  current_month: Current Month
  total_count: Total Insects Captured
  insect_count: Total Insects Captured
  previous_month: Previous Month
  same_month_previous_year: Previous Year
  hourly_insect_count: Hourly Insect Activity
  weekly_insect_count: Insect Activity by Weekday
  weekly_and_hourly_insect_count: Insect Activity by Weekday and Hour
  sum: Sum ({sensorCount} sensors)
  top5: Top 5 Species
  title: Monitoring Summary
  all_insects_option: Total of all species
  alert: Alerts
  field:
    sensor: Sensor
    insect_type: Insect Type
</i18n>

<script>
import theme from '@/libs/theme';
import { addToMonthString } from '@/libs/time';

import MonthPicker from '@/components/atoms/MonthPicker';
import SelectBox from '@/components/atoms/SelectBox';
import PageTitleArea from '@/components/atoms/PageTitleArea';
import InsectTypeSelectBox from '@/components/molecules/InsectTypeSelectBox';
import NoticeHistory from './NoticeHistory';
import AlertLegend from './AlertLegend';
import { eventDataInMonth } from './chartCalculations';
import DailyInsectCountChart from './DailyInsectCountChart';
import HourlyBarChart from './HourlyBarChart';
import InsectTypePieChart from './InsectTypePieChart';
import TotalCountPanel from './TotalCountPanel';
import WeekdayHourMatrixChart from './WeekdayHourMatrixChart';
import WeekdayBarChart from './WeekdayBarChart';
import { getSensorDummyEvents } from './dummyData';

const convertEvents = (events, insectTypeFilter) => {
  if (!insectTypeFilter) {
    return events.map((event) => ({
      timestamp: event.timestamp,
      count: event.count,
      countDifference: event.count_difference,
      alert: event.sensor_threshold !== 0 && event.count >= event.sensor_threshold,
      increaseAlert: event.sensor_increase_threshold !== 0 &&
        event.count_difference >= event.sensor_increase_threshold,
      sensorId: event.sensor_id,
    }));
  }

  const result = events.map((event) => ({
    timestamp: event.timestamp,
    count: event.insect_counts[insectTypeFilter] || 0,
    sensorId: event.sensor_id,
    countDifference: 0,
  }));

  for (let i = 1; i < result.length; i += 1) {
    const event = result[i];
    const previousEvent = result[i - 1];
    event.countDifference = event.count - previousEvent.count;
  }

  return result;
};

export default {
  name: 'dashboard',
  props: {
  },
  components: {
    AlertLegend,
    DailyInsectCountChart,
    HourlyBarChart,
    InsectTypePieChart,
    InsectTypeSelectBox,
    MonthPicker,
    NoticeHistory,
    PageTitleArea,
    SelectBox,
    TotalCountPanel,
    WeekdayBarChart,
    WeekdayHourMatrixChart,
  },
  data() {
    return {
      currentMonth: this.$moment().format('YYYY-MM'),
      sensors: [],
      eventsBySensorId: {},
      maxDailyInsectCounts: [0, 0],
      insectTypeFilter: null,
      selectedSensorId: null,
    };
  },
  mounted: async function () {
    this.sensors = await this.fetchSensors();
    this.sensors.forEach((sensor) => {
      this.fetchEvents(sensor.id);
    });
  },
  computed: {
    comparedWithPreviousMonthGraphs() {
      return [
        {
          month: this.currentMonth,
          color: theme.primaryColor,
          label: this.$t('current_month'),
        },
        {
          month: addToMonthString(this.currentMonth, -1),
          color: '#19d276',
          label: this.$t('previous_month'),
        },
        {
          month: addToMonthString(this.currentMonth, -12),
          color: 'black',
          label: this.$t('same_month_previous_year'),
        },
      ];
    },
    currentMonthSelectedSensorEvents() {
      return eventDataInMonth(this.currentMonth, this.selectedSensorEvents);
    },
    currentMonthSelectedSensorData() {
      return eventDataInMonth(this.currentMonth, this.selectedSensorData);
    },
    displayingSum() {
      return this.selectedSensorId === null;
    },
    dailyInsectCountYMax() {
      const totalMaxCount = Math.max(...this.maxDailyInsectCounts);

      let roundTo = 5;
      if (totalMaxCount >= 100) {
        roundTo = 20;
      }

      return this.roundToNext(totalMaxCount, roundTo);
    },
    eventDataBySensorId() {
      const result = {};
      Object.entries(this.eventsBySensorId).forEach(([sensorId, events]) => {
        result[sensorId] = convertEvents(events, this.insectTypeFilter);
      });
      return result;
    },
    selectedSensor() {
      if (!this.selectedSensorId) {
        return null;
      }

      return this._.find(this.sensors, { id: this.selectedSensorId });
    },
    selectedSensorData() {
      if (!this.selectedSensor) {
        return Object.values(this.eventDataBySensorId).flat();
      }

      return this.eventDataBySensorId[this.selectedSensor.id];
    },
    selectedSensorEvents() {
      if (!this.selectedSensor) {
        return Object.values(this.eventsBySensorId).flat();
      }

      return this.eventsBySensorId[this.selectedSensor.id];
    },
    sensorSelectItems() {
      return [
        { value: null, text: this.$t('sum', { sensorCount: this.sensors.length }) },
        ...this.sensors.map(sensor => ({ value: sensor.id, text: sensor.name })),
      ];
    },
    showAlertPeriods() {
      return !this.displayingSum;
    },
  },
  inject: {
    fetchSensors: {
      default: () => async () => [
        { id: 's1_filling', name: 'Filling' },
        { id: 's2_packaging', name: 'Packaging' },
        { id: 's3_storage', name: 'Pre-shipment storage' },
      ],
    },
    fetchSensorEvents: {
      default: () => (sensorId) => getSensorDummyEvents(sensorId),
    },
  },
  methods: {
    fetchEvents: async function (sensorId) {
      const events = this.fetchSensorEvents(sensorId);

      this.eventsBySensorId = {
        ...this.eventsBySensorId,
        [sensorId]: events,
      };
    },
    roundToNext(value, increment) {
      return Math.ceil(value / increment) * increment;
    },
    updateMaxDailyInsectCount(graphIndex, updatedValue) {
      const newValue = [...this.maxDailyInsectCounts];
      newValue[graphIndex] = updatedValue;
      this.maxDailyInsectCounts = newValue;
    },
  },
};
</script>

<style scoped lang="sass">
.filter-area
  background-color: #fff

  & > *
    margin-left: 8px
    margin-right: 8px

.graph-area
  padding: 16px

  h2
    font-size: 1.25rem
    font-weight: normal
    padding-bottom: 16px
</style>
