<template>
  <select-box
    :items="options"
    :value="value"
    @input="v => $emit('input', v)"
    v-bind="$attrs"
  />
</template>

<i18n lang="yaml">
</i18n>

<script>
import InsectTypes from '@/mixins/insectTypes';

import SelectBox from '@/components/atoms/SelectBox';

export default {
  name: 'insect-type-select-box',
  mixins: [
    InsectTypes,
  ],
  components: {
    SelectBox,
  },
  props: {
    value: {
      required: true,
    },
    dataVersion: {
      type: String,
    },
    nullOption: {
      type: String,
    },
  },
  computed: {
    options() {
      const result = [...this.INSECT_TYPES_FOR_OPTION];

      if (this.nullOption) {
        result.unshift({ value: null, text: this.nullOption });
      }

      return result;
    },
  },
};
</script>

<style lang="sass" scoped>
  .select-box
    flex-grow: 0
    width: 250px
</style>
