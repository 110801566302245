<template>
  <div class="insect-view-detections-editor">
    <insect-positions-canvas
      :src="imageSrc"
      :mode="editMode"
      :detection="detection"
      :disablePanZoom="editMode != 'control'"
      disable-context
      @pick="showForm"
      @add="addDetection"
      ref="canvas"
    />
    <insect-type-picker
      @change="onChangeDetections"
      @close="hideForm"
      :disabled="editMode == 'control'"
      :withInsectTypes="options.withInsectTypes"
      ref="form"
    />
  </div>
</template>

<script>
import InsectTypes from '@/mixins/insectTypes';
import InsectPositionsCanvas from '@/components/InsectPositionsCanvas';
import InsectTypePicker from './InsectTypePicker';

const COLOR_DEFAULT = 'red';
const COLOR_REMOVE = 'white';

export default {
  name: 'detections-editor',
  mixins: [InsectTypes],
  props: {
    editMode: String,
    imageSrc: String,
    options: {
      type: Object,
      default() {
        return {
          withInsectTypes: true, // Boolean
          defaultInsectType: null, // Number(symbol of INSECT_TYPE)
          originalImageHeight: null, // Number
          originalImageWidth: null, // Number
        };
      },
    },
    value: {
      type: Array,
      default() {
        /*
         * Sample attributes
         *
         * - index: 1
         * - position: {}
         * - symbol: '7
         */
        return [];
      },
    },
  },
  components: {
    InsectTypePicker,
    InsectPositionsCanvas,
  },
  computed: {
    detection() {
      const {
        withInsectTypes,
        originalImageHeight: height,
        originalImageWidth: width,
      } = this.options;

      return {
        height,
        width,
        rects: this.value.map(value => ({
          ...value,
          symbol: withInsectTypes ? value.symbol : '',
          color: this.getDetectionColor(value),
        })),
      };
    },
  },
  methods: {
    getDetectionColor(item) {
      if (item.remove) {
        return COLOR_REMOVE;
      }
      return COLOR_DEFAULT;
    },
    hideForm() {
      const canvas = this.$refs.canvas;
      canvas.clear(canvas.$refs.editLayer);
    },
    addDetection(position) {
      const newDetection = {
        position,
        index: this.value.length,
        added: true,
      };
      const {
        defaultInsectType,
        withInsectTypes,
      } = this.options;
      if (withInsectTypes) {
        this._.merge(newDetection, {
          ...this.INSECT_TYPES[defaultInsectType],
          type: defaultInsectType,
        });
      }
      this.$emit('input', [...this.value, newDetection]);
      const canvas = this.$refs.canvas;
      canvas.clear(canvas.$refs.editLayer);
    },
    showForm(items, event) {
      this.$refs.form.show(items, {
        top: event.y,
        left: event.x,
      });
    },
    onChangeDetections(detections) {
      const _ = this._;
      const modified = this.value.map(value => _.find(detections, { index: value.index }) || value);
      this.$emit('input', _.reject(modified, { remove: true, added: true }));
    },
  },
};
</script>

<style scoped lang="sass">
</style>
