var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "select-box",
    _vm._b(
      {
        attrs: { items: _vm.options, value: _vm.value },
        on: {
          input: function (v) {
            return _vm.$emit("input", v)
          },
        },
      },
      "select-box",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }