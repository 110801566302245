<template>
  <div class="d-flex flex-column align-center">
    <div
      class="total-count"
      data-testid="total-count"
    >
      {{ formatTotalCount(currentMonthTotal) }}
    </div>
    <div
      :class="relativePercentageClasses(changeFromPreviousMonth)"
      data-testid="percentage-vs-previous-month"
    >
      {{ formatRelativePercentage(changeFromPreviousMonth) }}
      <span class="value-direction">
        {{ valueDirectionGlyph(changeFromPreviousMonth) }}
      </span>
    </div>
    <div>{{ $t('vs_previous_month') }}</div>
    <div
      :class="relativePercentageClasses(changeFromPreviousYear)"
      data-testid="percentage-vs-previous-year"
    >
      {{ formatRelativePercentage(changeFromPreviousYear) }}
      <span class="value-direction">
        {{ valueDirectionGlyph(changeFromPreviousYear) }}
      </span>
    </div>
    <div>{{ $t('vs_previous_year') }}</div>
  </div>
</template>

<i18n lang="yaml">
ja:
  vs_previous_month: 前月比
  vs_previous_year: 昨年同月比

en:
  vs_previous_month: vs previous month
  vs_previous_year: vs previous year
</i18n>

<script>
import moment from 'moment';

import { addToMonthString } from '@/libs/time';

import { eventDataInMonth, eventDataUntilDay } from './chartCalculations';

const sumTotalCaptures = (events) => events.reduce(
  (acc, event) => acc + Math.max(event.countDifference, 0),
  0,
);

// TODO: Don't filter when currentMonth is in the past
const filterComparablePastPeriod = (events) => {
  // It makes only sense to compare past data up until the same day
  // If today is 2024/10/04 -> only compare with 2024/09/01~2024/09/04 and 2023/10/01~2023/10/04
  let currentDay = moment().date();
  const lastDayOfMonth = moment().endOf('month').date();

  // But if it's the last day of the month use all past data
  if (currentDay === lastDayOfMonth) {
    return events;
  }

  // Event starts on Oct 22 - have at least as many data
  currentDay = Math.max(currentDay, 22);

  return eventDataUntilDay(currentDay, events);
};

export default {
  name: 'total-count-panel',
  props: {
    // { timestamp: number, count: number }
    eventData: {
      type: Array,
      required: true,
    },
    // { month: 'YYYY-MM', color: HTMLColor, label: str }
    month: {
      type: String,
      required: true,
    },
  },
  computed: {
    currentMonthTotal() {
      const month = this.month;
      const events = eventDataInMonth(month, this.eventData);
      return sumTotalCaptures(events);
    },
    changeFromPreviousMonth() {
      if (this.previousMonthTotal === 0) {
        return undefined;
      }

      return (this.currentMonthTotal / this.previousMonthTotal) - 1;
    },
    changeFromPreviousYear() {
      if (this.previousYearTotal === 0) {
        return undefined;
      }

      return (this.currentMonthTotal / this.previousYearTotal) - 1;
    },
    previousMonthTotal() {
      const month = addToMonthString(this.month, -1);
      const events = filterComparablePastPeriod(
        eventDataInMonth(month, this.eventData),
      );
      return sumTotalCaptures(events);
    },
    previousYearTotal() {
      const month = addToMonthString(this.month, -12);
      const events = filterComparablePastPeriod(
        eventDataInMonth(month, this.eventData),
      );
      return sumTotalCaptures(events);
    },
  },
  methods: {
    formatRelativePercentage(value) {
      if (value === undefined) {
        return 'N/A';
      }

      const sign = value > 0 ? '+' : '';
      const roundedPercentage = (value * 100).toFixed(2);

      return `${sign}${roundedPercentage}%`;
    },
    formatTotalCount(value) {
      // To have comma between every 3 digits
      return value.toLocaleString();
    },
    relativePercentageClasses(value) {
      const result = {
        'relative-percentage': true,
      };
      if (value >= 0) {
        result.bad = true;
      } else if (value < 0) {
        result.good = true;
      }
      return result;
    },
    valueDirectionGlyph(value) {
      if (value > 0) {
        return '▲';
      } else if (value < 0) {
        return '▼';
      }

      return '';
    },
  },
};
</script>

<style scoped lang="sass">
.total-count
  font-size: 2rem
  font-weight: bold

.relative-percentage
  font-size: 1.5rem
  margin-bottom: -6px

  .value-direction
    font-size: 1rem

.good
  color: green

.bad
  color: red
</style>
