var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        { attrs: { align: "center" } },
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("h3", [_vm._v(_vm._s(_vm.description.department_name))]),
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("span", { staticClass: "insect-route" }, [
              _vm._v(_vm._s(_vm.description.route)),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "4" } }, [
            _c("img", {
              staticClass: "photo",
              attrs: { src: _vm.insectPhotos[_vm.insectType] },
            }),
          ]),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "8" } }, [
            _vm._v("\n      " + _vm._s(_vm.description.description) + "\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }