import InsectTypes from '@/mixins/insectTypes';

export default {
  mixins: [InsectTypes],
  methods: {
    addInsectCountDifferences(events) {
      const previousEvents = {};
      const sortedEvents = events.sort((a, b) => a.timestamp - b.timestamp);
      return sortedEvents.map((event) => {
        const previousEvent = previousEvents[event.sensor_id];
        previousEvents[event.sensor_id] = event;
        return {
          ...event,
          insect_count_differences: this.calcInsectCountDifferences(event, previousEvent),
        };
      });
    },
    calcInsectCountDifferences(event, previousEvent) {
      return Object.keys(this.INSECT_TYPES).reduce((acc, insectType) => {
        const count = event.insect_counts[insectType] || 0;
        if (!previousEvent) {
          acc[insectType] = 0;
          return acc;
        }

        const previousCount = previousEvent.insect_counts[insectType] || 0;
        acc[insectType] = Math.max(count - previousCount, 0);
        return acc;
      }, {});
    },
    calcRouteCounts(insectCounts) {
      return Object.keys(insectCounts).reduce((acc, insectType) => {
        const routeType = this.INSECT_TYPES[insectType].route;
        acc[routeType] = (acc[routeType] || 0) + insectCounts[insectType];
        return acc;
      }, {});
    },
  },
};
