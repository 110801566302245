import Vue from 'vue';
import Router from 'vue-router';

import store from '@/store';

import * as Admin from '@/components/admin';
import App from '@/components/App';
import AppSignedOut from '@/components/AppSignedOut';
import SignIn from '@/components/SignIn';
import SignUp from '@/components/SignUp';
import ForgotPassword from '@/components/ForgotPassword';
import SensorForm from '@/components/SensorForm';
import SensorList from '@/components/SensorList';
import StationPlaces from '@/components/pages/StationPlaces';
import StationPlaceEvents from '@/components/pages/StationPlaceEvents';
import StationPlaceRecentEvents from '@/components/pages/StationPlaceRecentEvents';
import Activity from '@/components/pages/Activity';
import UserForm from '@/components/UserForm';
import OrganizationForm from '@/components/OrganizationForm';
import InformationTab from '@/components/InformationTab';
import MirakunDevices from '@/components/pages/MirakunDevices';
import InstallationSiteList from '@/components/pages/InstallationSiteList';
import InstallationSite from '@/components/pages/InstallationSite';
import ActivityLogs from '@/components/pages/admin/ActivityLogs';
import Dashboard from '@/components/pages/Dashboard';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/sign_in',
      component: AppSignedOut,
      meta: { mustBeSignedOut: true },
      children: [
        {
          path: '',
          component: SignIn,
        },
        {
          path: '/register',
          component: SignUp,
        },
        {
          path: '/forgot_password',
          component: ForgotPassword,
        },
      ],
    },
    {
      path: '/admin',
      component: Admin.default,
      props: {
        admin: true,
      },
      meta: { authRequired: true, requiresPermission: 'admin' },
      children: [
        {
          path: '',
          redirect: 'sensors',
        },
        {
          path: 'sensors',
          component: Admin.SensorList,
        },
        {
          path: 'sensors/:id',
          name: 'admin-sensor',
          component: SensorForm,
          props: {
            admin: true,
          },
        },
        {
          path: 'accounts',
          component: Admin.AccountList,
        },
        {
          path: 'accounts/new',
          name: 'new-admin-account',
          component: UserForm,
          props: {
            admin: true,
            newRecord: true,
          },
        },
        {
          path: 'accounts/:email',
          name: 'admin-account',
          component: UserForm,
          props: {
            admin: true,
          },
        },
        {
          path: 'organizations',
          component: Admin.OrganizationList,
        },
        {
          path: 'organizations/new',
          name: 'new-admin-organization',
          component: OrganizationForm,
          props: {
            newRecord: true,
            admin: true,
          },
        },
        {
          path: 'organizations/:id',
          name: 'admin-organization',
          component: OrganizationForm,
          props: {
            admin: true,
          },
        },
        {
          path: 'installation_sites',
          component: InstallationSiteList,
          props: {
            admin: true,
          },
        },
        {
          name: 'admin-installation_site',
          path: 'installation_sites/:id',
          component: InstallationSite,
          props: route => ({
            admin: true,
            id: route.params.id,
          }),
        },
        {
          path: 'activity_logs/:id',
          component: ActivityLogs,
        },
        {
          path: 'activity_logs/:id/:date',
          component: ActivityLogs,
        },
      ],
    },
    {
      path: '/',
      component: App,
      meta: { authRequired: true },
      children: [
        {
          path: '',
          redirect: 'activities',
        },
        {
          path: 'activities',
          component: Activity,
        },
        {
          path: 'sensors',
          component: SensorList,
        },
        {
          path: 'station',
          component: StationPlaces,
          meta: { requiresPermission: 'station' },
        },
        {
          path: 'station/:placeId',
          component: StationPlaceEvents,
          meta: { requiresPermission: 'station' },
          props: route => ({ placeId: route.params.placeId }),
        },
        {
          path: 'station/:placeId/recent',
          component: StationPlaceRecentEvents,
          meta: { requiresPermission: 'station' },
          props: route => ({ placeId: route.params.placeId }),
        },
        {
          path: 'sensors/new',
          component: SensorForm,
          props: {
            new_record: true,
          },
        },
        {
          path: 'sensors/:id',
          name: 'sensor',
          component: SensorForm,
        },
        {
          path: 'account',
          component: UserForm,
        },
        {
          path: 'organization',
          component: OrganizationForm,
        },
        {
          path: 'information',
          component: InformationTab,
        },
        {
          path: 'mirakun_device',
          component: MirakunDevices,
        },
        {
          path: 'installation_sites',
          component: InstallationSiteList,
        },
        {
          name: 'installation_site',
          path: 'installation_sites/:id',
          component: InstallationSite,
          props: route => ({ id: route.params.id }),
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          component: Dashboard,
        },
      ],
    },
  ],
});

const requiredPermissions = route => (
  route.matched.map(r => r.meta.requiresPermission).filter(r => r)
);

const currentUserHasRoutePermissions = (route) => {
  if (!store.getters.isSignedIn || !store.getters.currentUserRetrieved) {
    return false;
  }
  return requiredPermissions(route).every(p => store.getters.hasPermission(p));
};

const isAdminPage = route => requiredPermissions(route).includes('admin');

router.beforeEach((to, from, next) => {
  if (to.matched.some(r => r.meta.authRequired)) {
    if (!store.getters.isSignedIn) {
      next({ path: '/sign_in', query: { redirectTo: to.path } });
      return;
    }
    if (!currentUserHasRoutePermissions(to)) {
      next('/');
      return;
    }
    if (store.getters.hasPermission('admin') && !isAdminPage(to)) {
      next('/admin');
      return;
    }
  }

  if (to.matched.some(r => r.meta.mustBeSignedOut) && store.getters.isSignedIn) {
    next('/');
    return;
  }

  // ページ遷移時にスクロール状態が残る不具合への暫定対応
  // OPTIMIZE: https://github.com/agileware-jp/pest-vision/issues/229
  document.documentElement.scrollTop = 0;
  document.body.scrollTop = 0;

  next();
});

export default router;
