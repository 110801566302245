import { groupBy } from 'lodash';
import moment from 'moment';

export const dailyInsectCounts = (dates, eventData) => dates.map(date => {
  const dataOnDate = eventDataOnDate(date, eventData);

  if (dataOnDate.length === 0) {
    return null;
  }

  const dataBySensorId = groupBy(dataOnDate, 'sensorId');

  let result = 0;

  Object.values(dataBySensorId).forEach(dataForSensor => {
    const counts = dataForSensor.map(({ count }) => count);
    result += Math.max(...counts);
  });

  return result;
});

export const dailyAlerts = (dates, eventData) => dates.map(date => {
  const dataOnDate = eventDataOnDate(date, eventData);

  return {
    total: dataOnDate.some(({ alert }) => alert),
    increase: dataOnDate.some(({ increaseAlert }) => increaseAlert),
  };
});

const eventDataOnDate = (date, eventData) => {
  const minTimestamp = moment(date).unix();
  const maxTimestamp = moment(date).endOf('day').unix();

  return eventData.filter(({ timestamp }) =>
    timestamp >= minTimestamp && timestamp <= maxTimestamp,
  );
};

export const eventDataInMonth = (month, eventData) => {
  const minTimestamp = moment(month).unix();
  const maxTimestamp = moment(month).endOf('month').unix();

  return eventData.filter(({ timestamp }) =>
    timestamp >= minTimestamp && timestamp <= maxTimestamp,
  );
};

export const eventDataUntilDay = (day, eventData) =>
  eventData.filter(({ timestamp }) => moment.unix(timestamp).date() <= day);
