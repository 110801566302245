<template>
  <matrix-chart :data="chartData" :options="chartOptions" />
</template>

<script>
import MatrixChart from '@/components/atoms/MatrixChart';

const HOUR_LABELS = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];

export default {
  name: 'weekday-hour-matrix-chart',
  props: {
    eventData: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    MatrixChart,
  },
  computed: {
    chartData() {
      // Minimum of 1 in case all counts are 0 to avoid NaN
      const maxAlphaCount = Math.max(this._.maxBy(this.chartValues, 'count').count, 1);

      return {
        datasets: [
          {
            backgroundColor(context) {
              const count = context.dataset.data[context.dataIndex].count;
              const alpha = (count / maxAlphaCount);
              return `rgba(25, 118, 210, ${alpha})`;
            },
            data: this.chartValues,
            borderWidth: 1,
            borderColor: 'rgba(0, 0, 0, 0.5)',
            width: ({ chart }) => ((chart.chartArea || {}).width / 24) - 1,
            height: ({ chart }) => ((chart.chartArea || {}).height / 7) - 1,
          },
        ],
      };
    },
    chartValues() {
      const countByWeekdayAndHour = Array(7).fill(0).map(() => Array(24).fill(0));

      this.eventData.forEach(({ timestamp, countDifference }) => {
        const date = new Date(timestamp * 1000);

        const value = countDifference < 0 ? 0 : countDifference;

        countByWeekdayAndHour[date.getDay()][date.getHours()] += value;
      });

      const result = [];
      countByWeekdayAndHour.forEach((countByHour, weekday) => {
        countByHour.forEach((count, hour) => {
          result.push({
            x: HOUR_LABELS[hour],
            y: this.weekdayLabels[weekday],
            count,
          });
        });
      });
      return result;
    },
    chartOptions() {
      return {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title() {
                return '';
              },
              label(context) {
                const v = context.dataset.data[context.dataIndex];
                return [
                  `${v.y} ${v.x}`,
                  `count: ${v.count}`,
                ];
              },
            },
          },
        },
        scales: {
          x: {
            type: 'category',
            labels: HOUR_LABELS,
            grid: {
              display: false,
            },
          },
          y: {
            type: 'category',
            labels: this.weekdayLabelsForYAxis,
            offset: true,
            grid: {
              display: false,
            },
          },
        },
      };
    },
    weekdayLabels() {
      const labels = {
        ja: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日'],
        en: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      };
      return labels[this.$i18n.locale];
    },
    weekdayLabelsForYAxis() {
      // Y Axis is from bottom to top
      // Sunday, Saturday, ..., Monday
      const indexes = [0, 6, 5, 4, 3, 2, 1];
      return indexes.map(index => this.weekdayLabels[index]);
    },
  },
};
</script>

<style scoped lang="sass">
</style>
