var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "entity-list-layout",
    {
      staticClass: "sensor-list",
      attrs: {
        title: "SENSOR",
        count: _vm.sensors.length,
        query: _vm.search,
        "search-hint": _vm.$t("search"),
      },
      on: {
        "update:query": function ($event) {
          _vm.search = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "title-area-content",
          fn: function () {
            return [_vm.creatable ? _c("create-sensor-button") : _vm._e()]
          },
          proxy: true,
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "p-data-list",
        {
          attrs: {
            pagination: _vm.pagination,
            data: _vm.sensors,
            getItemProps: _vm.genItemProps,
          },
          on: {
            "update:pagination": function ($event) {
              _vm.pagination = $event
            },
          },
        },
        [
          _c("data-field", {
            attrs: { name: "No", headerClass: "column--no" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.index + 1))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.type"),
              value: "type_index",
              headerClass: "column--type",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("sensor-type-label", { attrs: { sensor: props.item } }),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.identifier"),
              value: "id",
              headerClass: "column--id",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("span", { staticClass: "sensor-list__sensor-id" }, [
                      _vm._v(
                        "\n          " + _vm._s(props.item.id) + "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.name"),
              value: "name",
              headerClass: "column--name",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "router-link",
                      {
                        staticClass: "sensor-list__sensor-name",
                        attrs: {
                          to: { name: "sensor", params: { id: props.item.id } },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(props.item.name) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.installation_date"),
              value: "installed_on",
              headerClass: "column--installed-on",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(
                      _vm._s(
                        _vm._f("moment")(props.item.installed_on, "YYYY/MM/DD")
                      )
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.threshold"),
              value: "threshold",
              headerClass: "column--threshold",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c("div", { style: _vm._f("thresholdStyle")(props.item) }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("threshold")(props.item)) +
                          "\n        "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.installation_site"),
              value: "place",
              headerClass: "column--place",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.place))]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.organization_name"),
              value: "created_by.organization_name",
              headerClass: "column--organization",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _vm._v(_vm._s(props.item.created_by.organization_name)),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("data-field", {
            attrs: {
              name: _vm.$t("field.created_by"),
              value: "created_by.name",
              headerClass: "column--user",
              sortable: "",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [_vm._v(_vm._s(props.item.created_by.name))]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }