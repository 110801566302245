import moment from 'moment';
import _ from 'lodash';

import insectIds from 'Data/insect_ids.json';

import { addToMonthString } from '@/libs/time';

const randomFromArray = (array) => {
  const randomIndex = Math.floor(Math.random() * array.length);
  return array[randomIndex];
};

const INSECT_TYPES = Object.keys(insectIds.versions['4']);

const INSECT_DISTRIBUTION = {
  yusurika_ka: 16,
  choubae_ka: 5,
  kurobkurobanekinokobae_ka: 4,
  azamiuma: 3,
  nomibae_ka: 2,
  random_other: 1,
};

let nextInsects = [];

const randomInsect = () => {
  if (nextInsects.length === 0) {
    Object.entries(INSECT_DISTRIBUTION).forEach(([insect, count]) => {
      nextInsects = [...nextInsects, ...Array(count).fill(insect)];
    });

    nextInsects = _.shuffle(nextInsects);
  }

  let result = nextInsects.pop();

  if (result === 'random_other') {
    const otherInsectTypes = INSECT_TYPES.filter(
      (insect) => !Object.keys(INSECT_DISTRIBUTION).includes(insect),
    );
    result = randomFromArray(otherInsectTypes);
  }

  return result;
};

const INSECT_CORE_HOURS = {
  yusurika_ka: [
    { start: 19, counts: [2, 2, 2, 4, 4, 4, 4, 4, 2, 2, 2] },
    { start: 7, counts: [1, 2, 1] },
  ],
  default: [
    { start: 7, counts: [3, 2, 2, 2, 2, 2, 2, 2, 2, 3] },
  ],
};

const nextHoursByInsect = {};
Object.keys(INSECT_CORE_HOURS).forEach((insect) => {
  nextHoursByInsect[insect] = [];
});

const randomHour = (insect) => {
  const type = Object.hasOwn(INSECT_CORE_HOURS, insect) ? insect : 'default';

  if (nextHoursByInsect[type].length === 0) {
    const coreHours = INSECT_CORE_HOURS[type];
    coreHours.forEach((coreHour) => {
      coreHour.counts.forEach((count, offset) => {
        const hour = (coreHour.start + offset + 24) % 24;

        for (let i = 0; i < count; i += 1) {
          nextHoursByInsect[type].push(hour);
        }
      });
    });

    nextHoursByInsect[type] = _.shuffle(nextHoursByInsect[type]);
  }

  return nextHoursByInsect[type].pop();
};

const randomInsectAppearances = (count) => {
  const insectCountsByHour = {};

  for (let i = 0; i < count; i += 1) {
    const insect = randomInsect();
    const hour = randomHour(insect);
    if (!insectCountsByHour[hour]) {
      insectCountsByHour[hour] = {};
    }
    insectCountsByHour[hour][insect] = (insectCountsByHour[hour][insect] || 0) + 1;
  }

  const result = [];

  for (let i = 0; i < 24; i += 1) {
    if (insectCountsByHour[i]) {
      result.push({
        insectCounts: insectCountsByHour[i],
        hour: i,
      });
    }
  }

  // [{ insectCounts: { yusurika_ka: 1, choubae_ka: 2 }, hour: 13 }, ...]
  return result;
};

const createDummyData = (month, graphSections, thresholds) => {
  const beginDate = moment(month);
  let endDate = moment(month).add(1, 'month');

  const tomorrow = moment().add(1, 'day');
  if (endDate.isAfter(tomorrow)) {
    endDate = tomorrow.startOf('day');
  }
  // Event starts on Oct 22 - have at least as many data
  if (beginDate.isSame('2024-10-01') && endDate.isBefore('2024-10-23')) {
    endDate = moment('2024-10-23');
  }

  const result = [];
  let insectCounts = {};
  let count = 0;

  const sensorThreshold = thresholds.threshold || 40;
  const sensorIncreaseThreshold = thresholds.increase_threshold || 3;

  while (beginDate.isBefore(endDate)) {
    const day = beginDate.date();
    const section = graphSections.find((s) => s.until >= day);

    if (section.count > 0) {
      const increase = section.count - count;
      if (increase > 0) {
        const appearances = randomInsectAppearances(increase);
        // eslint-disable-next-line no-loop-func
        appearances.forEach((appearance) => {
          const { insectCounts: newInsectCounts, hour } = appearance;

          insectCounts = { ...insectCounts }; // Copy
          Object.entries(newInsectCounts).forEach(([insect, increaseCount]) => {
            insectCounts[insect] = (insectCounts[insect] || 0) + increaseCount;
          });
          count = _.sum(Object.values(insectCounts));

          result.push({
            timestamp: beginDate.clone().hour(hour).unix(),
            count,
            insect_counts: insectCounts,
            sensor_threshold: sensorThreshold,
            sensor_increase_threshold: sensorIncreaseThreshold,
          });
        });
      }
    } else {
      insectCounts = {};
      count = 0;
    }

    result.push({
      timestamp: beginDate.clone().hour(23).unix(),
      count,
      insect_counts: insectCounts,
      sensor_threshold: sensorThreshold,
      sensor_increase_threshold: sensorIncreaseThreshold,
    });

    beginDate.add(1, 'day');
  }

  return result;
};

export const CURRENT_MONTH = moment().format('YYYY-MM');
const PREVIOUS_MONTH = addToMonthString(CURRENT_MONTH, -1);
const PREVIOUS_YEAR = addToMonthString(CURRENT_MONTH, -12);

const DUMMY_DATA = {
  s3_storage: [
    ...createDummyData(
      CURRENT_MONTH,
      [
        { count: 20, until: 3 },
        { count: 45, until: 11 },
        { count: 50, until: 15 },
        { count: 0, until: 19 },
        { count: 13, until: 25 },
        { count: 15, until: 27 },
        { count: 18, until: 29 },
        { count: 20, until: 31 },
      ],
      {
        threshold: 40,
      },
    ),
    ...createDummyData(
      PREVIOUS_MONTH,
      [
        { count: 5, until: 3 },
        { count: 7, until: 8 },
        { count: 10, until: 10 },
        { count: 20, until: 15 },
        { count: 0, until: 21 },
        { count: 4, until: 27 },
        { count: 5, until: 28 },
        { count: 7, until: 31 },
      ],
      {
        threshold: 40,
      },
    ),
    ...createDummyData(
      PREVIOUS_YEAR,
      [
        { count: 20, until: 3 },
        { count: 25, until: 7 },
        { count: 30, until: 10 },
        { count: 40, until: 15 },
        { count: 0, until: 24 },
        { count: 7, until: 27 },
        { count: 10, until: 31 },
      ],
      {
        threshold: 30,
      },
    ),
  ],
  s1_filling: [
    // ...createMonthlyDummyData(CURRENT_MONTH, 0, 100, 10),
    // ...createMonthlyDummyData(PREVIOUS_MONTH, 0, 100, 10),
    // ...createMonthlyDummyData(PREVIOUS_YEAR, 0, 100, 10),
  ],
  s2_packaging: [
    // ...createMonthlyDummyData(CURRENT_MONTH, 0, 100, 10),
    // ...createMonthlyDummyData(PREVIOUS_MONTH, 0, 100, 10),
    // ...createMonthlyDummyData(PREVIOUS_YEAR, 0, 100, 10),
  ],
};

export const getSensorDummyEvents = (sensorId) => {
  let previousCount = 0;

  const result = [];

  DUMMY_DATA[sensorId].forEach((data) => {
    // Event JSON
    result.push({
      ...data,
      count_difference: data.count - previousCount,
      sensor_id: sensorId,
    });

    previousCount = data.count;
  });

  return result;
};
