<template>
  <div>
    <v-row align="center">
      <v-col cols="auto">
        <h3>{{ description.department_name }}</h3>
      </v-col>
      <v-col cols="auto">
        <span class="insect-route">{{ description.route }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <img :src="insectPhotos[insectType]" class="photo" />
      </v-col>
      <v-col cols="8">
        {{ description.description }}
      </v-col>
    </v-row>
  </div>
</template>

<script>
import insectDescriptions from 'Data/insect_descriptions.json';
import insectPhotos from 'Data/insect_photos';

export default {
  name: 'insect-description',
  props: {
    insectType: String,
  },
  data() {
    return {
      insectDescriptions,
      insectPhotos,
    };
  },
  computed: {
    description() {
      return this.insectDescriptions[this.insectType][this.$i18n.locale];
    },
    isIndoor() {
      return this.description.route === 'indoor' || this.description.route === '内部発生';
    },
  },
};
</script>

<style scoped lang="sass">
.photo
  width: 100%

.insect-route
  font-weight: bold
  color: white
  padding: 5px
  border-radius: 5px
  background-color: #5e803f
</style>
