var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "notice-history" },
    _vm._l(_vm.notices, function (notice) {
      return _c(
        "div",
        { key: notice.id, staticClass: "notice", class: notice.type },
        [
          _c(
            "div",
            { staticClass: "badge" },
            [
              _c("v-icon", [_vm._v(_vm._s(_vm.getNoticeIcon(notice.type)))]),
              _vm._v(" "),
              _c("span", { staticClass: "mx-auto" }, [
                _vm._v(_vm._s(_vm.getNoticeLabel(notice.type))),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "message" }, [
            _c("div", { staticClass: "mr-2" }, [_vm._v(_vm._s(notice.date))]),
            _vm._v(" "),
            _c("div", [_vm._v(_vm._s(notice.message))]),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }