import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {
  get,
  compact,
  includes,
  last,
  sortBy,
  toPairs,
} from 'lodash';

import mixinLocale from './common/locale';

Vue.use(VueI18n);

// eslint-disable-next-line
const i18n = new VueI18n({
  locale: 'en',
  messages: {
    en: {
      rules: {
        camera: 'Camera',
        upload: 'Upload',
        health_check: 'Health Check',
        authentication: 'Authentication',
        settings: 'Settings',
        rssi: 'Network(RSSI)',
        ping: 'Network(PING)',
        wifi: 'Network(Wi-Fi)',
        eth: 'Network(Ethernet)',
        mount: 'Memory Card(mount)',
        use: 'Memory Card(used)',
        ntpd: 'NTPD',
      },
      messages: {
        ok: 'Normal',
        connectionFailed: 'Connection failed',
        goodQuality: '{rssi}dBm',
        poorQuality: '{rssi}dBm, Poor quality',
        packetLoss: '{rate}% packet loss, Poor quality',
        lowPacketLoss: '{rate}% packet loss',
        unrecognized: 'Unrecognized',
        unmounted: 'Unmounted',
        lessSpace: '{rate}% used, Too less disk space',
        enoughSpace: '{rate}% used',
        noServers: 'No servers reachable',
      },
      status: {
        '-1': 'Initializing',
        100: 'Connection Timeout',
        101: 'Disconnected',
        102: 'An unexpected error occurred',
        200: 'Connection Error',
        201: 'An unexpected error occurred',
        300: 'Connection Error',
        301: 'An unexpected error occurred',
        401: 'Authentication information is missing',
        403: 'Authentication failed',
        500: 'Connection Error',
        501: 'An unexpected error occurred',
      },
    },
    ja: {
      rules: {
        camera: 'カメラ',
        upload: 'アップロード機能',
        health_check: 'ライフチェック',
        settings: 'センサー設定機能',
        authentication: '機器認証機能',
        rssi: 'ネットワーク状態(RSSI)',
        ping: 'ネットワーク状態(PING)',
        wifi: 'ネットワーク状態(Wi-Fi)',
        eth: 'ネットワーク状態(Ethernet)',
        mount: 'SDカード(マウント状態)',
        use: 'SDカード(使用容量)',
        ntpd: 'NTPD状態',
      },
      messages: {
        ok: '異常なし',
        connectionFailed: '接続されていません',
        goodQuality: '{rssi}dBm',
        poorQuality: '{rssi}dBm, 通信が不安定です',
        packetLoss: 'パケットロス率 {rate}%, 通信が不安定です',
        lowPacketLoss: 'パケットロス率 {rate}%',
        unrecognized: 'デバイスが認識されていません',
        unmounted: 'マウントされていません',
        lessSpace: '{rate}% 使用中。空き容量が少なくなっています',
        enoughSpace: '{rate}% 使用中',
        noServers: 'サーバーと同期できていません',
      },
      status: {
        '-1': '初期化中',
        100: '接続がタイムアウトしました',
        101: '接続されていません',
        102: '予期せぬエラーが発生しました',
        200: '接続に失敗しました',
        201: '予期せぬエラーが発生しました',
        300: '接続に失敗しました',
        301: '予期せぬエラーが発生しました',
        401: '認証情報が設定されていません',
        403: '認証に失敗しました',
        500: '接続に失敗しました',
        501: '予期せぬエラーが発生しました',
      },
    },
  },
});

export default {
  computed: {
    HEALTH_CHECK_RULES_V2() {
      return [
        {
          name: 'camera',
          check: (status) => {
            const logs = get(status, 'camera.status_log');
            const camera = last(sortBy(logs, 'time')) || {};
            if (includes(camera.status, 1)) {
              return {
                status: 'error',
                message: i18n.t('messages.connectionFailed', this.i18n_locale),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.ok', this.i18n_locale),
            };
          },
        },
        {
          name: 'rssi',
          check: (status) => {
            const logs = get(status, 'network.rssi_log');
            const rssi = last(sortBy(logs, 'time')) || {};
            if (rssi.rssi < -30) {
              return {
                status: 'error',
                message: i18n.t('messages.poorQuality', this.i18n_locale, rssi),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.goodQuality', this.i18n_locale, rssi),
            };
          },
        },
        {
          name: 'ping',
          check: (status) => {
            const logs = get(status, 'network.ping_log');
            const ping = last(sortBy(logs, 'time')) || {};
            if (ping.ping > 30) {
              return {
                status: 'error',
                message: i18n.t('messages.packetLoss', this.i18n_locale, { rate: ping.ping }),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.lowPacketLoss', this.i18n_locale, { rate: ping.ping }),
            };
          },
        },
        {
          name: 'wifi',
          check: (status) => {
            const logs = get(status, 'network.wifi_log');
            const wifi = last(sortBy(logs, 'time')) || {};
            if (wifi.wifi === 1) {
              return {
                status: 'error',
                message: i18n.t('messages.unrecognized', this.i18n_locale),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.ok', this.i18n_locale),
            };
          },
        },
        {
          name: 'eth',
          check: (status) => {
            const logs = get(status, 'network.eth_log');
            const eth = last(sortBy(logs, 'time')) || {};
            if (eth.eth === 1) {
              return {
                status: 'error',
                message: i18n.t('messages.unrecognized', this.i18n_locale),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.ok', this.i18n_locale),
            };
          },
        },
        {
          name: 'mount',
          check: (status) => {
            if (get(status, 'microsd.mount') === 1) {
              return {
                status: 'error',
                message: i18n.t('messages.unmounted', this.i18n_locale),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.ok', this.i18n_locale),
            };
          },
        },
        {
          name: 'use',
          check: (status) => {
            const used = get(status, 'microsd.use');
            if (used > 80) {
              return {
                status: 'error',
                message: i18n.t('messages.lessSpace', this.i18n_locale, { rate: used }),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.enoughSpace', this.i18n_locale, { rate: used }),
            };
          },
        },
        {
          name: 'ntpd',
          check: (status) => {
            if (get(status, 'ntpd') === 1) {
              return {
                status: 'error',
                message: i18n.t('messages.noServers', this.i18n_locale),
              };
            }
            return {
              status: 'success',
              message: i18n.t('messages.ok', this.i18n_locale),
            };
          },
        },
      ];
    },
  },
  methods: {
    healthCheck(sensor) {
      const { health_check_status: status } = sensor;
      if (get(status, 'app_info')) {
        const results = this.HEALTH_CHECK_RULES_V2.map((rule) => {
          const result = rule.check(status);
          return {
            name: i18n.t(`rules.${rule.name}`, this.i18n_locale),
            status: result.status,
            message: result.message,
          };
        });
        return compact(results);
      }
      const results = toPairs(status).map(([name, value]) => {
        if (value !== 0) {
          return {
            name: i18n.t(`rules.${name}`, this.i18n_locale),
            status: 'error',
            message: i18n.t(`status.${value}`, this.i18n_locale),
          };
        }
        return null;
      });
      return compact(results);
    },
  },
  ...mixinLocale,
};
